import React from 'react';
import { Link } from 'gatsby';
// import styles from './404.module.scss';

const Error404 = () => (
      <div>
        {'Ooops ! Page not found'}
        {' '}
        <br />
        {'View our'}
        {' '}
        <Link to="/products">{'products'}</Link>
      </div>
    
);

export default Error404;